import Slider, { Marks } from "rc-slider";
import "../../scss/config/RangeSlider.scss";
import "rc-slider/assets/index.css";
import React from "react";

interface ComponentProps {
    min: number;
    max: number;
    dots?: boolean;
    marks?: Marks;
    step?: number | null;
    defaultValue: number;
    value: number;
    onChange?(value: number): void;
    additionalClass?: string;
    hasSmallDots?: boolean;
    skipAddClassMethod?: boolean;
}

class RangeSlider extends React.Component<ComponentProps> {
    addClass = (value: number): void => {
        if (this.props.skipAddClassMethod) {
            return;
        }

        const currentValue = value;

        setTimeout(() => {
            document
                .querySelectorAll(".rc-slider-mark .rc-slider-mark-text")
                .forEach((item: any, index: number) => {
                    if (index === currentValue) {
                        item.classList.add("rc-slider-mark-text-current");
                    } else {
                        item.classList.remove("rc-slider-mark-text-current");
                    }
                });
        }, 0);
    };

    componentDidMount(): void {
        this.addClass(this.props.value);
    }

    componentDidUpdate(): void {
        this.addClass(this.props.value);
    }

    render() {
        return (
            <React.Fragment>
                <Slider
                    className={
                        "range-slider" +
                        (this.props.additionalClass
                            ? " " + this.props.additionalClass
                            : "") +
                        (this.props.hasSmallDots
                            ? " range-slider--small-dots"
                            : "")
                    }
                    min={this.props.min}
                    max={this.props.max}
                    dots={this.props.dots}
                    marks={this.props.marks}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    step={this.props.step}
                />
            </React.Fragment>
        );
    }
}

export default RangeSlider;
