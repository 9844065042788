export interface SustainableType {
    type: string;
    label: string;
    boxList: Array<string>;
    svgIcon: string;
}

export const notSustainableEinzeltitel: Array<SustainableType> = [
    {
        type: "global",
        label: "Global",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich"
        ],
        svgIcon: "SVGGlobal"
    }
];

export const sustainableEinzeltitel: Array<SustainableType> = [
    {
        type: "global-sustainable",
        label: "Global nachhaltig",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich",
            "Auswahl der Finanzinstrumente nach Grundsätzen der Nachhaltigkeit (ESG-Kriterien)"
        ],
        svgIcon: "SVGGlobalSustainable"
    }
];

export const notSustainableFondsbausteine: Array<SustainableType> = [
    {
        type: "global-fonds",
        label: "Global Fonds",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich"
        ],
        svgIcon: "SVGGlobal"
    }
];

export const sustainableFondsbausteine: Array<SustainableType> = [
    {
        type: "global-sustainable-fonds",
        label: "Global Fonds nachhaltig",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich",
            "Auswahl der Finanzinstrumente nach Grundsätzen der Nachhaltigkeit (ESG-Kriterien)"
        ],
        svgIcon: "SVGGlobalSustainable"
    }
];

export const sustainableFondsbausteineDistributionFocus: Array<SustainableType> = [
    {
        type: "global-sustainable-distribution-focus",
        label: "Global nachhaltig Fonds Ausschüttungsfokus",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition zu 50% in Fremdwährung möglich",
            "Auswahl der Finanzinstrumente nach Grundsätzen der Nachhaltigkeit (ESG-Kriterien)",
            "Ausschüttungfokussiertes Management"
        ],
        svgIcon: "SVGGlobalSustainableAusschuetungsfokus"
    }
];
